import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from "lodash";
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="">
        {posts &&
          posts.map(({ node: post, index }) => (
            <Link to={post.fields.slug}>
              <article key={index}
                className="blog-list-item flex items-center">
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                 <div className="ml-6">
                    <span className="label">
                      {post.frontmatter.title}
                    </span>
                    <span className="block text-sm text-gray-400 pb-2 mb-0" style={{textDecoration:`none`}}>
                      {post.frontmatter.date}
                    </span>
                    {post.frontmatter.tags ? (
                      post.frontmatter.tags.map((tag) => (                                          
                      <Link to={`/tags/${kebabCase(tag)}/`}>
                          <span className="tag-outlined text-sm text-gray-400">{tag}</span>
                      </Link>
                      ))
                    ) : null}
                  </div>
              </article>
            </Link>
          ))}
      </div>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "YYYY-MM-DD")
                  featuredpost
                  tags
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        height: 500
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
